@use './config/' as *;
@forward './main-scss/';
@forward './module/static/index';
@forward './module/static';
.waf-static {
    @extend %page-head-blue-bg;
    .waf-body {
        max-width: unset;
    }
    .card {
        &-info {
            @extend %mb-4;
        }
        &-title {
            @extend %body-l;
            @extend %primary-50;
        }
        &-description {
            @include truncate-text(6);
            @extend %body-l;
            @extend %neutral-100;
        }
    }
    .meta {
        color: var(--neutral-40);
        @extend %body-l;
    }
}
.waf-common-static {
    .layout-wrapper {
        max-width: unset;
    }
}
@include mq(col-tablet) {
    .waf-static {
        .waf-body {
            max-width: var(--lg-container-max-width);
            margin-top: var(--space-20-neg);
        }
        .card {
            &-description {
                @include truncate-text(2);
            }
        }
    }
}